<div class="top-margin-20 information-container">
  <!--full claims amount-->
  <div class="summary-item">
    <div class="black-title">Teljes követelés</div>
    <div class="flex vcenter black-title">
      <mat-progress-spinner
        *ngIf="loading"
        mode="indeterminate"
        diameter="24"
      ></mat-progress-spinner>
      <span>
        {{
          summaryStepService.summaryStepData.total_claims_amount
            | number : "1.0" : "hu"
        }}
        {{
          summaryStepService.summaryStepData.currency_iso
        }}
      </span>
    </div>
  </div>

  <!--debtor description-->
  <div class="debtor-description top-margin-5">
    {{ summaryStepService.summaryStepData.debtor_name }} adósnak összesen ennyit
    kell megfizetnie
  </div>

  <!--optional claims-->
  <div
    class="optional-claims top-margin-20"
    *ngIf="summaryStepService.summaryStepData.type !== FormTypeEnum.SOFT"
  >
    <app-optional-claim-title></app-optional-claim-title>
    <!--ügyindítási díj-->
    <div
      class="top-margin-10"
      *ngIf="summaryStepService.summaryStepData.type === FormTypeEnum.HARD"
    >
      <payee-toggle
        [selected]="true"
        [disabled]="true"
        [text]=startCaseFeeLabel
        matTooltip="Fizetési meghagyás esetén automatikusan az ügyindítási díj
        is hozzáadódik a követelésedhez, amit az adósnak kell megfizetnie."
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
      ></payee-toggle>
    </div>
    <!--közjegyzői díj-->
    <div
      class="top-margin-10"
      *ngIf="summaryStepService.summaryStepData.type === FormTypeEnum.HARD"
    >
      <payee-toggle
        [selected]="true"
        [disabled]="true"
        [text]=administrativeFeeLabel
        matTooltip="Fizetési meghagyás esetén automatikusan az eljárási díj
        is hozzáadódik a követelésedhez, amit az adósnak kell megfizetnie."
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
      ></payee-toggle>
    </div>
    <!--törvényes kamatok-->
    <div class="top-margin-10">
      <payee-toggle
        [disabled]="loading"
        [selected]="summaryStepService.summaryStepData.is_added_interest_claim"
        [text]="interestAmountLabel"
        (selectionChanged)="onInterestClick($event)"
        matTooltip="A teljes követelés megfizetéséig felhalmozódó kamatok érvényesítése"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
        direction="top"
      ></payee-toggle>
    </div>
    <!--40 eur-->
    <div
      class="top-margin-10"
      *ngIf="
        summaryStepService.summaryStepData
          .has_claims_eligible_for_flat_rate_cost
      "
    >
      <payee-toggle
        [selected]="
          summaryStepService.summaryStepData.is_added_flat_rate_cost_claim
        "
        [disabled]="loading"
        [text]=euroLabel
        (selectionChanged)="onFortyEurosClick($event)"
        matTooltip="Az egy éven belül lejárt követelésekre követelésenként
        40 EUR-nak megfelelő összeg érvényesítése"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
      ></payee-toggle>
    </div>
  </div>
</div>
