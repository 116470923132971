import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { SummaryStepData } from "../models/summary-step-data";
import { Claim } from "../../init-step/models/claims-and-legal-relationship/claim";
import { Fee } from "../models/fee";
import axios, { AxiosError } from "axios";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CasesService } from "src/app/services/cases/cases.service";
import { ClientsService } from "src/app/services/clients/clients.service";
import { filter, first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SummaryStepService {
  summaryStepData: SummaryStepData;
  startCasePrice: number = 0;
  closedSuccessCasePrice: number = 0;
  casePriceTitle: Record<keyof SummaryStepData['fees'], { label: string; amount: string; }> = {
    "closed-success": {
      amount: '0',
      label: 'Sikerdíj összege',
    },
    start: {
      amount: '0',
      label: 'Fizetendő ügyindítási díj',
    },
  };

  caseUuid = '';
  private _partnerLogoName = '';

  get getPartnerLogoName(): string { return this._partnerLogoName; }

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private casesService: CasesService,
    private clientsService: ClientsService,
  ) { }

  setCaseUuid(caseUuid: string) {
    this.caseUuid = caseUuid;
  }

  async setSummaryStepData(): Promise<{ redirecting: boolean; }> {
    try {
      const data: any = await this.casesService.getCaseForm(this.caseUuid, 'summary');
      this.setData(data.summary_form_data);
      return {
        redirecting: false,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 403) {
          if (error.response.data.code === 'init-data-invalid') {
            this.snackbar.open('Néhány adat nincs kitöltve!', 'OK');
            await this.router.navigateByUrl(`case/${this.caseUuid}/data-entry`);
            return {
              redirecting: true,
            };
          } else if ('payee_case_reference_id' in error.response.data) {
            await this.router.navigate(['/user/cases'], {
              queryParams: {
                filterType: 'payeeId',
                filter: error.response.data.payee_case_reference_id,
              },
            });
            return {
              redirecting: true,
            };
          }
        }
      }
      console.error('Error while loading summary step data', error);
      throw error;
    }
  }

  async saveSummaryStepData(): Promise<void> {
    const url = `${environment.baseUrl}/api/payee-case/${this.caseUuid}/summary`;
    const params = {
      is_added_flat_rate_cost_claim: this.summaryStepData.is_added_flat_rate_cost_claim,
      is_added_interest_claim: this.summaryStepData.is_added_interest_claim,
    };
    const response = await axios.patch<{ summary_form_data: SummaryStepData; }>(url, params);
    this.setData(response.data.summary_form_data);
  }

  setData(summaryStepData: SummaryStepData) {
    this.clientsService.clientsSubject
      .pipe(filter(v => !!v?.length), first())
      .subscribe({
        next: clients => {
          const client = clients.find(c => c.id === summaryStepData.client_id);
          this.clientsService.setSelectedClient(client);
        }
      });

    this.summaryStepData = {
      ...summaryStepData,
      claims: this.getClaimsData(summaryStepData),
      fees: summaryStepData.fees,
    };
    this._partnerLogoName = summaryStepData.partner?.integration ?? '';

    this.startCasePrice = summaryStepData.fees.start.reduce((sum, fee) => fee.amount + sum, 0);
    this.closedSuccessCasePrice = summaryStepData.fees["closed-success"].reduce((sum, fee) => fee.amount + sum, 0);

    this.casePriceTitle.start.amount = `${this.startCasePrice.toLocaleString('hu-HU')} ${summaryStepData.currency_iso}`;
    this.casePriceTitle["closed-success"].amount = `${this.closedSuccessCasePrice.toLocaleString('hu-HU')} ${summaryStepData.currency_iso}`;
  }

  getClaimsData(summaryStepData: SummaryStepData): Claim[] {
    const claims = [];

    for (const claim of summaryStepData.claims) {
      claims.push({
        uuid: claim.uuid,
        amount: claim.amount,
        due_date_at: claim.due_date_at
      } as Claim);
    }

    return claims;
  }
}
